<template>
  <el-dialog
    v-model="isVisible"
    width="40%"
    title="Assign Roles"
    :before-close="handleClose"
    :show-close="true"
  >
    <el-form
      ref="domain-form2"
      :model="form"
      :rules="rules"
      :show-message="true"
      class="row"
      label-position="top"
      status-icon
    >
      <el-form-item label="Name" prop="content" class="col-sm-12 col-md-6">
        <el-input class="w-100" disabled v-model="form.name" @keydown.space="preventDefault" />
      </el-form-item>
      <el-form-item label="Username" prop="content" class="col-sm-12 col-md-6">
        <el-input class="w-100" disabled v-model="form.username" @keydown.space="preventDefault" />
      </el-form-item>
      <el-form-item label="Email" prop="content" class="col-12">
        <el-input class="w-100" disabled v-model="form.email" @keydown.space="preventDefault" />
      </el-form-item>
      <el-form-item label="Assigned Role" prop="options" class="col-12">
        <el-select v-model="form.role_id" filterable class="w-100">
          <el-option
            v-for="item in roleList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="item.name === 'ADMIN'"
          />
        </el-select>
      </el-form-item>
      <div v-if="error" class="col-12 mb-1">
        <el-alert :title="error" type="error" :closable="false" show-icon />
      </div>
      <el-form-item class="col-12 mt-2 dialog-buttons">
        <el-button
          class="col-12 col-sm-auto mb-2 mb-sm-0 mr-0 mr-sm-2"
          type="primary"
          @click="handleSubmit"
          >Save</el-button
        >
        <el-button class="col-12 col-sm-auto" @click="handleClose">Cancel</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  export default {
    name: 'RolesUsersEditDialog',
    emits: ['closeEditDialog', 'refreshDt'],
    props: {
      showDialog: {
        type: Boolean,
        default: () => false,
      },
      target: {
        default: () => null,
      },
    },
    data() {
      return {
        isVisible: false,
        form: {
          name: '',
          email: '',
          username: '',
          role_id: '',
        },
        rules: {
          name: [{ required: true, message: `An valid name is required.`, trigger: 'change' }],
          permissions: [
            { required: true, message: 'Select at least one option.', trigger: 'change' },
          ],
        },
        error: '',
      }
    },
    computed: {
      roleList() {
        return this.$store.getters['roleManagement/roles_data']
          ? this.$store.getters['roleManagement/roles_data']
          : []
      },
    },
    methods: {
      handleClose() {
        this.isVisible = false
        this.form = {
          name: '',
          email: '',
          username: '',
          role_id: '',
        }
        this.error = ''
        this.$emit('closeEditDialog')
      },
      handleSubmit() {
        this.$confirm(
          'This will assign a new role with relevant permissions in dashboard to the user. Confirm?',
          'Warning',
          {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning',
          },
        )
          .then(() => {
            this.updateUser()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Operation canceled.',
            })
          })
      },
      async updateUser() {
        try {
          const id = this.target.id
          const res = await this.$services.users.update(id, { 'role_id': this.form.role_id })
          if (!res) {
            this.$message({
              type: 'error',
              message: 'Unknown Error.',
            })
          } else {
            this.$message({
              type: 'success',
              message: 'Successfully assign new role to the user.',
            })
            this.$emit('refreshDt')
            this.handleClose()
          }
        } catch (error) {
          this.$message({
            type: 'error',
            message: error.response.data.data[0].field + ': ' + error.response.data.data[0].error,
          })
        }
      },
      preventDefault(event) {
        event.preventDefault()
      },
      findRoleIDbyRoleName(roleName) {
        const role = this.roleList.filter(item => item.name === roleName)
        if (role.length > 0) return role[0].id
        return ''
      },
    },
    watch: {
      showDialog(newVal) {
        if (newVal) this.isVisible = newVal
      },
      target(newVal) {
        if (newVal) {
          this.form = {
            name: newVal.name,
            email: newVal.email,
            username: newVal.username,
            role_id: this.findRoleIDbyRoleName(newVal.role),
          }
        }
      },
    },
  }
</script>

<style scoped></style>
