<template>
  <main class="row" v-loading="loading">
    <div class="card">
      <div class="card-header pb-0">
        <div class="col-auto ms-auto text-end mt-n1">
          <el-select class="border-0" v-model="pageSize" placeholder="Select" size="mini">
            <el-option
              v-for="item in pageOptions"
              :key="item"
              :label="item.type"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <table class="table table-striped table-hover mb-5 mt-2" style="width: 100%">
        <thead>
          <tr>
            <th>
              <a v-on:click="handleSortIconClick('first')" v-if="firstSortIcon % 3 === 0">
                Name <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('first')" v-if="firstSortIcon % 3 === 1">
                Name <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('first')" v-if="firstSortIcon % 3 === 2">
                Name <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-none d-xxl-table-cell">
              <a v-on:click="handleSortIconClick('second')" v-if="secondSortIcon % 3 === 0">
                Email <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('second')" v-if="secondSortIcon % 3 === 1">
                Email <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('second')" v-if="secondSortIcon % 3 === 2">
                Email <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-none d-xxl-table-cell">
              <a v-on:click="handleSortIconClick('third')" v-if="thirdSortIcon % 3 === 0">
                Username <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('third')" v-if="thirdSortIcon % 3 === 1">
                Username <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('third')" v-if="thirdSortIcon % 3 === 2">
                Username <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-xxl-table-cell">
              <a v-on:click="handleSortIconClick('fourth')" v-if="fourthSortIcon % 3 === 0">
                Role <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('fourth')" v-if="fourthSortIcon % 3 === 1">
                Role <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('fourth')" v-if="fourthSortIcon % 3 === 2">
                Role <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-xl-table-cell text-end" v-if="validatedManagePermission">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paginatedTableData" :key="item.id">
            <td>
              <div style="font-size:0.95rem">
                <strong>{{ item.name }}</strong>
              </div>
            </td>
            <td class="d-none d-xxl-table-cell">
              <div style="font-size:0.95rem">{{ item.email }}</div>
            </td>
            <td class="d-none d-xxl-table-cell">
              <div style="font-size:0.95rem">{{ item.username }}</div>
            </td>
            <td class="d-xxl-table-cell">
              <el-tag :type="statusToTag(item.role)">{{ item.role.toUpperCase() }}</el-tag>
            </td>
            <td class="d-xl-table-cell text-end" v-if="validatedManagePermission">
              <div v-if="item.role.toUpperCase() !== 'ADMIN'">
                <el-button type="primary" size="mini" @click="handleEditOnClick(item)">
                  <i class="fas fa-edit fa-lg" />
                </el-button>
              </div>
              <div v-else>
                <el-tag type="info"> Untouchable </el-tag>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- pagination -->
      <nav>
        <div class="row">
          <div class="col-sm-12 col-md-5">
            <span>Show {{ lowerBound }} to {{ upperBound }} of {{ usersDt.length }} Users</span>
          </div>
          <div class="col-sm-12 col-md-7">
            <ul class="pagination justify-content-end me-3">
              <li class="page-item" :class="selectedPage === 1 ? 'disabled' : ''">
                <a class="page-link border-0" v-on:click="handlePagination(-1)">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>

              <li
                v-for="page in paginationIndexes"
                :key="page"
                class="page-item"
                :class="isSelected(page) ? 'active' : ''"
              >
                <a class="page-link border-0" v-on:click="handlePaginationIndex(page)">
                  {{ page }}
                </a>
              </li>

              <li
                class="page-item"
                :class="selectedPage === paginationIndexes.length ? 'disabled' : ''"
              >
                <a class="page-link border-0" v-on:click="handlePagination(1)">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </main>
  <RolesUsersEditDialog
    :show-dialog="showEditDialog"
    :target="selectedUser"
    @closeEditDialog="handleCloseDialog"
    @refreshDt="loadUsers"
  />
</template>

<script>
  import RolesUsersEditDialog from '@/views/Roles/RolesUsersEditDialog'
  export default {
    name: 'RolesUsersTable',
    components: { RolesUsersEditDialog },
    props: {
      usersDt: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        showEditDialog: false,
        selectedUser: null,
        firstSortIcon: 0,
        secondSortIcon: 0,
        thirdSortIcon: 0,
        fourthSortIcon: 0,
        selectedPage: 1,
        pageSize: 10,
        pageOptions: [
          { type: '10 users', value: 10 },
          { type: '20 users', value: 20 },
          { type: '50 users', value: 50 },
        ],
      }
    },
    computed: {
      validatedManagePermission() {
        return this.$store.getters['roleManagement/manage_permission_validation']
      },
      upperBound() {
        const res = this.selectedPage * this.pageSize
        return res > this.usersDt.length ? this.usersDt.length : res
      },
      lowerBound() {
        return (this.selectedPage - 1) * this.pageSize + 1
      },
      tableData() {
        /* check whether User ask to sort or not */
        /* pass the param you want to sort, and descending or ascending (-1 or 1) */
        if (this.firstSortIcon % 3 === 1) return this.sortedData('first', 1)
        else if (this.firstSortIcon % 3 === 2) return this.sortedData('first', -1)
        if (this.secondSortIcon % 3 === 1) return this.sortedData('second', 1)
        else if (this.secondSortIcon % 3 === 2) return this.sortedData('second', -1)
        if (this.thirdSortIcon % 3 === 1) return this.sortedData('third', 1)
        else if (this.thirdSortIcon % 3 === 2) return this.sortedData('third', -1)
        if (this.fourthSortIcon % 3 === 1) return this.sortedData('fourth', 1)
        else if (this.fourthSortIcon % 3 === 2) return this.sortedData('fourth', -1)
        return this.usersDt
      },
      paginatedTableData() {
        const initialIdx = this.pageSize * (this.selectedPage - 1)
        return this.tableData.slice(initialIdx, initialIdx + this.pageSize)
      },
      paginationIndexes() {
        const pages = Math.ceil(this.tableData.length / this.pageSize)
        let page_arr = []
        for (let i = 1; i <= pages; i++) {
          page_arr.push(i)
        }
        return page_arr
      },
    },
    methods: {
      loadUsers() {
        this.$store.dispatch('roleManagement/loadUsersData')
      },
      handleSortIconClick(col) {
        switch (col) {
          case 'first':
            this.firstSortIcon++
            break
          case 'second':
            this.secondSortIcon++
            break
          case 'third':
            this.thirdSortIcon++
            break
          default:
            this.fourthSortIcon++
        }
        this.resetSortIconValue(col)
      },
      resetSortIconValue(col) {
        if (col !== 'first') this.firstSortIcon = 0
        if (col !== 'second') this.secondSortIcon = 0
        if (col !== 'third') this.thirdSortIcon = 0
        if (col !== 'fourth') this.fourthSortIcon = 0
      },
      handlePagination(selectedPage) {
        this.selectedPage += selectedPage
      },
      handlePaginationIndex(selectedPage) {
        this.selectedPage = selectedPage
      },
      isSelected(pageIndex) {
        return pageIndex === this.selectedPage
      },
      statusToTag(type) {
        switch (type) {
          case 'ADMIN':
            return 'danger'
          case 'STAFF':
            return 'warning'
          default:
            return 'success'
        }
      },
      sortedData(param, sign) {
        let res = this.usersDt
        return res.sort((a, b) => {
          /* default is name */
          let paramA = a.name.toUpperCase() // ignore upper and lowercase
          let paramB = b.name.toUpperCase() // ignore upper and lowercase
          if (param === 'second') {
            paramA = a.email.toUpperCase() // ignore upper and lowercase
            paramB = b.email.toUpperCase() // ignore upper and lowercase
          }
          if (param === 'third') {
            paramA = a.username.toUpperCase() // ignore upper and lowercase
            paramB = b.username.toUpperCase() // ignore upper and lowercase
          }
          if (param === 'fourth') {
            paramA = a.role.toUpperCase() // ignore upper and lowercase
            paramB = b.role.toUpperCase() // ignore upper and lowercase
          }
          if (paramA < paramB) {
            return -1 * sign
          }
          if (paramA > paramB) {
            return 1 * sign
          }
          // if instance param value are same
          return 0
        })
      },
      handleCloseDialog() {
        this.showEditDialog = false
        this.selectedUser = null
      },
      handleEditOnClick(item) {
        console.log(item)
        this.showEditDialog = true
        this.selectedUser = item
      },
    },
    watch: {
      usersDt() {
        this.selectedPage = 1
      },
      pageSize() {
        this.selectedPage = 1
      },
    },
  }
</script>

<style scoped></style>
