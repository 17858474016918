<template>
  <div class="row mb-0 mb-xl-1">
    <div class="col-auto d-none d-sm-block">
      <h3><strong>Permission management</strong></h3>
      <span class="text-muted">
        Create or update Roles to users and manage their permissions.
      </span>
    </div>
    <div class="col-auto ms-auto text-end mt-n1">
      <div class="row">
        <div class="col-md-6 col-xl-auto col-md-auto p-1">
          <el-input
            v-model="filters.query"
            clearable
            placeholder="Search"
            prefix-icon="fas fa-search"
            class="w-100 card"
            style="max-width: 2100px;"
          />
        </div>
        <div class="col-md-6 col-xl-auto col-md-auto p-1">
          <el-select
            v-model="filters.types"
            clearable
            placeholder="Types"
            multiple
            collapse-tags
            class="w-100 card"
          >
            <el-option v-for="type in filterTypes" :key="type" :label="type" :value="type" />
          </el-select>
        </div>
        <div class="col-md-6 col-xl-auto p-1" v-if="validatedManagePermission">
          <el-button type="primary" plain class="w-100" @click="handleCreateOnClick">
            New Role
          </el-button>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <el-tabs class="p-0" v-model="activeTab">
      <el-tab-pane label="Users" name="Users">
        <div v-if="isUserMode">
          <RolesUsersTable :loading="loading" :users-dt="filteredData" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="Roles" name="Roles">
        <div v-if="!isUserMode">
          <RolesPermissionsTable :loading="loading" :role-permission-dt="filteredData" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
  <RolesCreateDialog
    :show-dialog="showCreateDialog"
    @closeCreateDialog="handleCreateDialogClose"
    @refreshDt="loadRoles"
  />
</template>

<script>
  import RolesPermissionsTable from '@/views/Roles/RolesPermissionsTable'
  import RolesUsersTable from '@/views/Roles/RolesUsersTable'
  import RolesCreateDialog from '@/views/Roles/RolesCreateDialog'
  export default {
    name: 'Roles',
    components: { RolesPermissionsTable, RolesUsersTable, RolesCreateDialog },
    data() {
      return {
        activeTab: 'Users',
        loading: false,
        tabPosition: 'left',
        showBtn: true,
        filters: {
          query: '',
          types: [],
        },
        showCreateDialog: false,
      }
    },
    watch: {
      activeTab() {
        // reset filter and query when table is switched
        this.filters.query = ''
        this.filters.types = []
      },
    },
    computed: {
      isUserMode() {
        return this.activeTab === 'Users'
      },
      filterTypes() {
        if (this.isUserMode) {
          if (this.rolesData) {
            return this.rolesData.map(item => item.name)
          }
          return []
        } else {
          if (this.permissionsData) {
            return this.permissionsData.map(item => item.name)
          }
          return []
        }
      },
      filteredData() {
        const { query = '', types = [] } = this.filters
        const sanitizedQuery = query.trim().toLowerCase()
        // if current table is: role-permission relations table
        if (!this.isUserMode) {
          if (this.rolesData) {
            return this.rolesData.filter(({ name, permissions }) => {
              return (
                [name].some(val => val.toLowerCase().includes(sanitizedQuery)) &&
                (!types.length || this.permissionFilter(types, permissions))
              )
            })
          } else {
            return []
          }
        } else {
          if (this.usersData) {
            return this.usersData.filter(({ name, email, username, role }) => {
              return [name, email, username].some(
                val =>
                  val.toLowerCase().includes(sanitizedQuery) &&
                  (!types.length || types.includes(role)),
              )
            })
          } else {
            return []
          }
        }
      },
      currentMode() {
        return this.isUserMode
      },
      usersData() {
        return this.$store.getters['roleManagement/users_data']
      },
      rolesData() {
        return this.$store.getters['roleManagement/roles_data']
      },
      permissionsData() {
        return this.$store.getters['roleManagement/permissions_data']
      },
      validatedManagePermission() {
        return this.$store.getters['roleManagement/manage_permission_validation']
      },
    },
    methods: {
      permissionFilter(filter, target) {
        let res = true
        filter.forEach(item => {
          let tmp = false
          target.forEach(elem => {
            if (elem.permission_name === item) tmp = true
          })
          if (!tmp) res = false
        })
        return res
      },
      handleCreateOnClick() {
        this.showCreateDialog = true
      },
      handleCreateDialogClose() {
        this.showCreateDialog = false
      },
      loadUsers() {
        this.$store.dispatch('roleManagement/loadUsersData')
      },
      loadRoles() {
        this.$store.dispatch('roleManagement/loadRolesData')
      },
      loadPermissions() {
        this.$store.dispatch('roleManagement/loadPermissionsData')
      },
      validateManagePermissions() {
        this.$store.dispatch('roleManagement/validateManagePermissions')
      },
    },
    mounted() {
      this.loadUsers()
      this.loadRoles()
      this.loadPermissions()
      this.validateManagePermissions()
    },
  }
</script>

<style scoped></style>
