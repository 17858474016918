<template>
  <el-dialog
    v-model="isVisible"
    width="40%"
    title="Edit existing role"
    :before-close="handleClose"
    :show-close="true"
  >
    <el-form
      ref="domain-form2"
      :model="form"
      :rules="rules"
      :show-message="true"
      class="row"
      label-position="top"
      status-icon
    >
      <el-form-item label="Role name" prop="content" class="col-12">
        <el-input
          class="w-100"
          placeholder="Please enter a name for the new role"
          v-model="form.name"
          @keydown.space="preventDefault"
        />
      </el-form-item>
      <el-form-item label="Permissions" prop="options" class="col-12">
        <el-select v-model="form.permissions" multiple filterable class="w-100">
          <el-option
            v-for="item in permissionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="item.name === 'MANAGE_PERMISSIONS'"
          />
        </el-select>
      </el-form-item>
      <div v-if="error" class="col-12 mb-1">
        <el-alert :title="error" type="error" :closable="false" show-icon />
      </div>
      <el-form-item class="col-12 mt-2 dialog-buttons">
        <el-button
          class="col-12 col-sm-auto mb-2 mb-sm-0 mr-0 mr-sm-2"
          type="primary"
          @click="handleSave"
          >Save</el-button
        >
        <el-button class="col-12 col-sm-auto" @click="handleClose">Cancel</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  export default {
    name: 'RolesPermissionsEditDialog',
    emits: ['closeUpdateDialog', 'refreshDt'],
    props: {
      showDialog: {
        type: Boolean,
        default: () => false,
      },
      target: {
        default: () => null,
      },
    },
    data() {
      return {
        isVisible: false,
        form: {
          name: '',
          permissions: [],
        },
        rules: {
          name: [{ required: true, message: `An valid name is required.`, trigger: 'change' }],
          permissions: [
            { required: false, message: 'Select at least one option.', trigger: 'change' },
          ],
        },
        error: '',
      }
    },
    computed: {
      permissionList() {
        const permissions = this.$store.getters['roleManagement/permissions_data']
        if (permissions) {
          let res = []
          permissions.forEach(item => {
            if (item.name !== 'MANAGE_PERMISSIONS') res.push(item)
          })
          return res
        }
        return []
      },
    },
    methods: {
      handleClose() {
        this.isVisible = false
        this.form = {
          name: '',
          permissions: [],
        }
        this.error = ''
        this.$emit('closeUpdateDialog')
      },
      handleSave() {
        this.$confirm(
          'This will update the role with new info and permissions on above. Confirm?',
          'Warning',
          {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning',
          },
        )
          .then(() => {
            this.updateRole()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Operation canceled.',
            })
          })
      },
      async updateRole() {
        try {
          const id = this.target.id
          const res = await this.$services.roles.update(id, this.form)
          if (!res) {
            this.$message({
              type: 'error',
              message: 'Unknown Error.',
            })
          } else {
            this.$message({
              type: 'success',
              message: 'Successfully updated the target role.',
            })
            this.$emit('refreshDt')
            this.handleClose()
          }
        } catch (error) {
          this.$message({
            type: 'error',
            message: error.response.data.data[0].field + ': ' + error.response.data.data[0].error,
          })
        }
      },
      preventDefault(event) {
        event.preventDefault()
      },
    },
    watch: {
      showDialog(newVal) {
        if (newVal) this.isVisible = newVal
      },
      target(newVal) {
        if (newVal) {
          this.form.name = newVal.name
          newVal.permissions.forEach(item => {
            this.form.permissions.push(item.permission_id)
          })
        }
      },
    },
  }
</script>

<style scoped></style>
